export const BankHolidays: string[] = [
    "13/04/2020",
    "04/05/2020",
    "01/06/2020",
    "03/08/2020",
    "26/10/2020",
    "25/12/2020",
    "26/12/2020",

    "01/01/2021",
    "17/03/2021",
    "05/04/2021",
    "03/05/2021",
    "07/06/2021",
    "02/08/2021",
    "25/10/2021",
    "25/12/2021",
    "26/12/2021",

    "01/01/2022",
    "17/03/2022",
    "18/03/2022",
    "18/04/2022",
    "02/05/2022",
    "06/06/2022",
    "01/07/2022",
    "31/10/2022",
    "25/12/2022",
    "26/12/2022",

    "01/01/2023",
    "06/02/2023",
    "17/03/2023",
    "10/04/2023",
    "01/05/2023",
    "05/06/2023",
    "07/07/2023",
    "30/10/2023",
    "25/12/2023",
    "26/12/2023",

    // The below are not confirmed yet
    "01/01/2024",
    "05/02/2024",
    "17/03/2024",
    "01/04/2024",
    "06/05/2024",
    "03/06/2024",
    "05/08/2024",
    "28/10/2024",
    "25/12/2024",
    "26/12/2024",
    "01/01/2025",

    "03/02/2025",
    "17/03/2025",
    "21/04/2025",
    "05/05/2025",
    "02/06/2025",
    "04/08/2025",
    "27/10/2025",
    "25/12/2025",
    "26/12/2025",
];
